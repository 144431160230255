import axios from 'axios'
import { baseConfig } from '../../config.js';

const state = {

    advertisementList: [],
    completeAdvertisementCount: 0,
    emptyAdvertisement: {
        name: "",
        banner_small: {
            banner_link: "",
            image_data: null
        },
        banner_medium: {
            banner_link: "",
            image_data: null
        },
        banner_large: {
            banner_link: "",
            image_data: null
        }
    },
    currentAdvertisement: {}
};

const getters = {

    advertisementList (state) {

        return state.advertisementList;
    },

    maxAdvertisementTableData (state) {

        return state.completeAdvertisementCount;        
    },

    emptyAdvertisement (state) {

        return state.emptyAdvertisement;
    },

    currentAdvertisement (state) {

        return state.currentAdvertisement;
    }
};

const mutations = {

    setAdvertisementList (state, list) {
      
        state.advertisementList = list;
    },

    setCompleteAdvertisementCount (state, count) {

        state.completeAdvertisementCount = count;
    },

    setCurrentAdvertisement (state, advertisement) {
        
        state.currentAdvertisement = advertisement;
    },
};

const actions = {
    
    getAdvertisementList (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.advertisement + payload.path, {
                params: {
                    "offset": payload.offset,
                    "count": payload.count,
                    "filter": payload.filter,
                    "sort_desc": payload.sort_desc
                }
            }).then((res) => {

                context.commit("setAdvertisementList", res.data.partners);
                resolve();
            }, () => {                
    
                reject();
            });
        });
    },

    getCompleteAdvertisementCount (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.advertisement + payload.path, {
                params: {
                    "filter": payload.filter
                }
            }).then((res) => {

                context.commit("setCompleteAdvertisementCount", res.data)
                resolve();
            }, () => {

                reject();
            })
        })
    },

    getAdvertisement (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.advertisement + payload.path, {
                params: {
                    "id": payload.id
                }
            })
            .then((res) => {

                context.commit("setCurrentAdvertisement", res.data);
                resolve();
            }, () => {
    
                reject();
            });
        });        
    },
    
    createAdvertisement (context, payload) {
        
        return new Promise ((resolve, reject) => {

            axios.post(baseConfig.baseUrl + baseConfig.specificUrlPath.advertisement + payload.path, payload.advertisement)
            .then((res) => {

                resolve(res.data);
            }, () => {
    
                reject();
            });
        });        
    },

    updateAdvertisement (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.post(baseConfig.baseUrl + baseConfig.specificUrlPath.advertisement + payload.path, payload.advertisement, {
                params: {
                    "id": payload.id
                },
            })
            .then(() => {

                resolve();
            }, () => {

                reject();
            });
        });
    },

    deleteAdvertisement (context, payload) {
        
        return new Promise ((resolve, reject) => {

            axios.delete(baseConfig.baseUrl + baseConfig.specificUrlPath.advertisement + payload.path, {
                params: {
                    "id": payload.id
                }
            }).then(() => {
    
                resolve();
            }, () => {
    
                reject();
            });
        });
    },

    getExportAdvertisements (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.billing + payload.path, {
                responseType : 'blob',
                contenType: 'text/plain',
                params: {
                    "start_year": payload.start_year,
                    "start_month": payload.start_month,
                    "end_year": payload.end_year,
                    "end_month": payload.end_month
                }
            }).then((res) => {

                resolve(res.data);
            }, () => {                
    
                reject();
            });
        });
    },
};

export default {

    namespaced: true,
    state,
    getters,
    mutations,
    actions
}