import axios from 'axios'
import { baseConfig } from '../../config.js';

const state = {

    authData: {
        accessToken: "",
        refreshToken: ""
    },
    showLogoutDialog: false
};

const getters = {

    authData (state) {

        return state.authData;
    },

    showLogoutDialog (state) {

        return state.showLogoutDialog;
    }
};

const mutations = {

    setAuthData (state, authData) {

        state.authData = authData;
    },

    resetAuthData (state) {

        state.authData.accessToken = "",
        state.authData.refreshToken = ""
    },

    setShowLogoutDialog (state, showLogoutDialog) {

        state.showLogoutDialog = showLogoutDialog;
    }
};

const actions = {

    setup (context, payload) {

        let lastError;

        // this interceptor adds the Authorization header containing the JWT token identifying
        // the login session to all requests except the request to the login / renewal endpoints.
        axios.interceptors.request.use((config) => {

            const accessToken = this.getters["login/authData"].accessToken
            if (!config.url.endsWith("/login") && !config.url.endsWith("/renewal") && accessToken !== "") {
                config.headers.Authorization = `Bearer ${accessToken}`
            }
            return config;
        }, (error) => {
            return error;
        });

        // this interceptor handles error responses caused by missing / expired authentication
        axios.interceptors.response.use((response) => {            
                // Pass success responses to the caller
                return response
            }, (error) => {

            // pass any non "not authorized" errors to the caller
            if (error.response.status !== 401) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            // if the error comes from the renewal endpoint it means that renewal has failed and we 
            // redirect the user to the login page so that they can log in again.
            if (error.config.url.endsWith('/renewal')) {
                console.log("renewal response failed")
                return new Promise((resolve, reject) => {
                    payload.router.push({ name: 'Login' });
                    reject(error);
                });
            }

            // if the error comes from the login it means that login has failed so we just hand 
            // out the error, we are already on the login page so no redirect to it is needed.
            if (error.config.url.endsWith('/login')) {             
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            //if the error comes if we want to take a logout, we don´t want to call session/renewal again
            if (error.config.url.endsWith('/verify')) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            //if the error is looped, because of an error 401 code
            if (lastError && lastError.response.status === 401) {

                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }            
            lastError = error;

            const refreshToken = this.getters["login/authData"].refreshToken
            const refreshObject = {
                refreshToken: refreshToken
            };

            return axios.post(baseConfig.baseUrl + "session/renewal", refreshObject, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {

                // store the new tokens for the next requests
                context.commit("setAuthData", { 
                    accessToken: response.data.access_token,
                    refreshToken: response.data.refresh_token
                });

                // update the original request with the new token so that it can be repeated
                const config = error.config;
                config.headers.Authorization = `Bearer ${response.data.access_token}`;
                console.log("repeat request")

                return new Promise((resolve, reject) => {
                  axios.request(config).then(response => {
                    resolve(response);
                  }).catch((error) => {
                    reject(error);
                  })
                });
            })
            .catch((response) => {                
                return new Promise((resolve, reject) => {                    
                    console.log("renewal request failed")
                    if (lastError && lastError.response.status === 401 && response && response.response.status === 401) {
                        reject();
                    } else {
                        payload.router.push({ name: 'Login' });
                        reject();
                    }
                });
            });
        });
    },

    loginUser (context, payload) {

        return new Promise((resolve, reject) => {

            let loginObject = {
                email: payload.username,
                password: payload.password
            };

            axios.post(baseConfig.baseUrl + "session/login", loginObject, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    context.commit("setAuthData", { 
                        accessToken: response.data.access_token,
                        refreshToken: response.data.refresh_token
                    });
                    resolve({ sucess: true });
                })
                .catch((response) => {
                    // TODO: get validation errors from response and display them
                    reject({ success: false, message: "Authentifizierung fehlgeschlagen. Bitte überprüfen Sie Benutzername und Passwort auf Schreibweise und versuchen es erneut." });
                });
          });
    },

    sessionCheck () {

        return axios.get(baseConfig.baseUrl + "session/verify")
    }
};

export default {

    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
