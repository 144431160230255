import axios from 'axios'
import { baseConfig } from '../../config.js';

const state = {

    sellerList: [],
    completeSellerCount: 0,    
};

const getters = {

    sellerList (state) {

        return state.sellerList;
    },

    maxSellerTableData (state) {

        return state.completeSellerCount;        
    },
};

const mutations = {

    setSellerList (state, list) {
      
        state.sellerList = list;
    },

    setCompleteSellerCount (state, count) {

        state.completeSellerCount = count;
    },
};

const actions = {
    
    getSellerList (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.seller + payload.path, {
                params: {
                    "offset": payload.offset,
                    "count": payload.count,
                    "filter": payload.filter,
                    "sort_desc": payload.sort_desc
                }
            }).then((res) => {

                context.commit("setSellerList", res.data.sellers);
                resolve();
            }, () => {
    
                reject();
            });
        });
    },

    getCompleteSellerCount (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.seller + payload.path, {
                params: {
                    "filter": payload.filter
                }
            }).then((res) => {

                context.commit("setCompleteSellerCount", res.data)
                resolve();
            }, () => {

                reject();
            })
        })
    },    

    updateSeller (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.post(baseConfig.baseUrl + baseConfig.specificUrlPath.seller + payload.path,
                { "active": payload.seller.active }, {
                params: {
                    "id": payload.seller.id
                },
            })
            .then(() => {

                resolve();
            }, () => {

                reject();
            });
        });
    },
    
    deleteSeller (context, payload) {
        
        return new Promise ((resolve, reject) => {

            axios.delete(baseConfig.baseUrl + baseConfig.specificUrlPath.seller + payload.path, {
                params: {
                    "id": payload.id
                }
            }).then(() => {
    
                resolve();
            }, () => {
    
                reject();
            });
        });
    }
};

export default {

    namespaced: true,
    state,
    getters,
    mutations,
    actions
}