<template>
    <v-dialog
        v-model="popupDialogVisible" @click:outside="rejected()" width="600">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{popupTitle}}
          </v-card-title>

          <v-card-text class="pa-4 subtitle-1">
            {{popupText}}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="rejected()">
              {{rejectedPopupButtonText}}
            </v-btn>

            <v-btn color="blue darken-1" text @click="accepted()">
              {{acceptedPopupButtonText}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>

export default {
    
    name: "Popup",
    props: ["popupDialogVisible", "popupTitle", "popupText", "rejectedPopupButtonText", "acceptedPopupButtonText"],

    methods: {

        rejected () {

            this.$emit("onRejected");
        },

        accepted () {

            this.$emit("onAccepted");
        }
    }
}
</script>

<style scoped>

</style>>