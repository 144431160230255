<template>
  <v-navigation-drawer :mini-variant.sync="collapse" mini-variant-width="90" app permanent>
    <div class="logo pa-3">
      <v-img
        :src="require('../assets/images/logo-text.png')"
        contain
      />
    </div>

    <v-divider class="mb-0 mt-0"></v-divider>

    <v-list class="pa-0">
      <v-list-item v-if="!$vuetify.breakpoint.smAndDown" @click="toggleNav()">
        <v-list-item-icon class="text-right">
          <v-icon>{{
            collapse ? "mdi-chevron-right" : "mdi-chevron-left"
          }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-0 mt-0"></v-divider>

      <v-list>
        <v-list-item
          v-for="item in navTree"
          :key="item.relatedView"
          color="primary"
          @click="navElementClicked(item)"
          :class="activeItemClass(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list>

    <popup
      :popupDialogVisible="logoutDialogVisible"
      popupTitle="Logout"
      popupText="Wollen Sie sich wirklich abmelden?"
      rejectedPopupButtonText="Nein"
      acceptedPopupButtonText="Ja"
      v-on:onRejected="closeLogoutDialog()"
      v-on:onAccepted="logout()">
    </popup>

  </v-navigation-drawer>
</template>

<script>

import Popup from "../components/Popup.vue";

export default {

  name: "Navigation",
  components: { Popup },
  props: ["navTree"],

  data: () => {

    return {

      toggleMini: false
    };
  },

  mounted () {

    window.onpopstate = (event) => {

      if (this.logoutDialogVisible) {

        history.go(1);
      }
    };
  },

  computed: {

    logoutDialogVisible () {

      return this.$store.getters["login/showLogoutDialog"];
    },

    collapse () {

      return this.$vuetify.breakpoint.smAndDown || this.toggleMini 
    }
  },

  methods: {

    toggleNav () {

        this.toggleMini = !this.toggleMini;
    },

    navElementClicked (item) {

      if(item.relatedView === this.navTree.login.relatedView) {

        this.$router.push({name: item.relatedComponent}).catch((err) => {});
      } else {

        this.$router.push({
          name: item.relatedComponent,
          params: {
            view: item.relatedView
          }
        }).catch((err) => {});
      }
    },

    activeItemClass (item) {

      if (this.$route.params.view === item.relatedView) {

        return "v-list-item--active"
      }
    },

    closeLogoutDialog () {

      this.$store.commit("login/setShowLogoutDialog", false);
    },

    logout () {

      this.$store.commit("login/setShowLogoutDialog", false);
      this.$store.commit("login/resetAuthData");
      this.$router.push({name: "Login"}).catch((err) => {});
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>