import axios from 'axios'
import { baseConfig } from '../../config.js';

const actions = {
    
    getBillings (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.billing + payload.path, {
                responseType : 'blob',
                contenType: 'text/plain',
                params: {
                    "month": payload.month,
                    "year": payload.year
                }
            }).then((res) => {

                resolve(res.data);
            }, () => {                
    
                reject();
            });
        });
    },
};

export default {

    namespaced: true,
    actions
}