<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <h1 class="text-h3">MGM - Verkäufer</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="sellerTableHeader"
          :footer-props="sellerTableFooter"
          :items="sellerTableData"
          :server-items-length="maxSellerTableData"
          :options.sync="sellerTableOptions"
          :loading="loading"
          must-sort
          @update:options="updateTableOptions"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                label="Märkte durchsuchen"
                single-line
                hide-details
                v-model="filterText"
                @input="onFilterChanged"
              ></v-text-field>
            </v-toolbar>
          </template>

          <template v-slot:[`item.confirmed`]="{ item }">
            <span> {{item.confirmed ? "Ja" : "Nein"}} </span>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              v-model="item.active"
              @click="updateSellerActiveStatus(item)"
              :disabled="!item.confirmed">
            </v-switch>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>

          <template v-slot:no-data>Keine Verkäufer gefunden</template>
        </v-data-table>
      </v-col>

      <popup
        :popupDialogVisible="deleteDialogVisible"
        popupTitle="Verkäuferaccount löschen"
        :popupText="deleteDialogText"
        rejectedPopupButtonText="Abbrechen"
        acceptedPopupButtonText="Löschen"
        v-on:onRejected="closeDeleteDialog()"
        v-on:onAccepted="deleteSeller()">
      </popup>

      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        transition="scroll-x-reverse-transition"
        top
        right
        >{{ snackbar.text }}</v-snackbar
      >
    </v-row>
  </v-container>
</template>

<script>

import Popup from "./Popup.vue";
import snackbar from '../mixins/snackbar'
import tabTitle from '../mixins/tabTitle';

export default {

  name: "Seller",
  mixins: [snackbar, tabTitle],
  components: { Popup },
  props: ["title"],

  data: () => {
    return {      
      
      loading: true,
      filterText: "",
      deleteDialogVisible: false,
      selectedSellerEntry: {},
      sellerTableHeader: [
        {
          text: "Markt",
          value: "store_name"
        },
        {
          text: "Verkäufer",
          value: "seller_name"
        },
        {
          text: "Marktbestätigung",
          value: "confirmed"
        },
        {
          text: "Aktiv",
          value: "active"
        },
        {
          text: "Aktionen",
          value: "actions",
          sortable: false,
          width: "100px"
        },
      ],
      sellerTableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: [false]
      },
      sellerTableFooter: {
        "items-per-page-options": [10,20,50]
      },
    };
  },

  computed: {

    sellerTableData () {

      return this.$store.getters["sellers/sellerList"];
    },

    maxSellerTableData () {

      return this.$store.getters["sellers/maxSellerTableData"];
    },

    deleteDialogText () {

      return 'Wollen Sie den Verkäuferaccount für "' + this.selectedSellerEntry.seller_name +
             '" vom Markt "' + this.selectedSellerEntry.store_name + '" wirklich löschen?'
    }
  },

  methods: {

    onFilterChanged () {
      
      this.sellerTableOptions.page = 1;
      this.updateTableOptions();
    },

    updateTableOptions () {

      this.loading = true;
      this.updateTable(this.sellerTableOptions.page - 1, this.sellerTableOptions.itemsPerPage, this.sellerTableOptions.sortDesc[0], this.filterText)
      .then(() => {

        this.loading = false;
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        this.loading = false;
      })
    },

    updateTable (offset, count, sort_desc, filter) {

      return new Promise ((resolve, reject) => {

        this.$store.dispatch("sellers/getSellerList", {
          path: "list",
          offset,
          count,
          filter,
          sort_desc
        }).then(() => {
          this.$store.dispatch("sellers/getCompleteSellerCount", {
            path: "count",
            filter
            }).then(() => resolve(), () => reject());
        }, () => reject());
      })      
    },

    updateSellerActiveStatus (seller) {

      this.updateDialogVisible = true;
      this.$store.dispatch("sellers/updateSeller", {
        path: "update",
        seller
      }).then(() => {

        this.updateTableOptions();
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren des Verkäufersstatus ist ein Fehler aufgetreten.");
      });
    },

    showDeleteDialog (seller) {
      
      this.deleteDialogVisible = true;
      this.selectedSellerEntry = seller;
    },

    closeDeleteDialog () {

      this.deleteDialogVisible = false;
    },
    
    deleteSeller () {

      this.deleteDialogVisible = false;
      this.$store.dispatch("sellers/deleteSeller", {
        path: "delete",
        id: this.selectedSellerEntry.id
      }).then(() => {

        this.showSnackbar("green", "Verkäufer wurde erfolgreich gelöscht.");
        this.updateTableOptions();
      }, () => {

        this.showSnackbar("red", "Beim Löschen des Verkäufers ist ein Fehler aufgetreten.");
      });      
    },
  }
};
</script>
