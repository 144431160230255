export const baseConfig = {

    baseUrl: process.env.VUE_APP_BASE_URL ? (process.env.VUE_APP_BASE_URL + "/") : "http://localhost:8000/management/",
    specificUrlPath: {        
        store: "store/",
        seller: "seller/",
        search: "store/search/boost/",
        billing: "billing/",
        advertisement: "advertisement/partner/"
    }
}
