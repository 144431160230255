<template>
  <v-app>
    <v-card class="login-view" :loading="loginInProgress">
      <v-form ref="loginForm" class="pa-10" v-model="loginForm.valid" @submit.prevent="login()">
        <v-container fluid>
          <v-row>
            <v-col class="pa-0 mb-4">
              <v-img :src="require('../assets/images/logo-text.png')" contain />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-0">
              <v-text-field
                v-model="loginForm.username"
                label="Benutzername"
                :rules="[v => !!v || 'Pflichtfeld']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-0">
              <v-text-field
                v-model="loginForm.password"
                label="Passwort"
                type="password"
                :rules="[v => !!v || 'Pflichtfeld']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-0 text-right">
              <v-btn color="primary" :disabled="loginInProgress" @click="login()" type="submit">
                Einloggen <v-icon right>mdi-lock-open-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      transition="scroll-x-reverse-transition"
      top
      right
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>

<script>

import tabTitle from '../mixins/tabTitle';

export default {

  name: "Home",
  mixins: [tabTitle],
  props: ["navTree"],
  title: "Login",

  data: () => {
    return {
      loginInProgress: false,

      loginForm: {
        valid: false,
        username: "admin@shob.store",
        password: "",
      },

      snackbar: {
        visible: false,
        timeout: 5000,
        color: "",
        text: "",
      },
    };
  },

  methods: {
    login() {
      if(!this.$refs.loginForm.validate()) {
        return;
      }
      this.loginInProgress = true;
      this.post_Login().then(
        this.loginSuccessfull,
        this.loginFailed
      );
    },

    loginSuccessfull(response) {
      this.loginInProgress = false;
      this.$router.push(
        {
          name: this.navTree.stores.relatedComponent,
          params: {
            view: this.navTree.stores.relatedView
          }
        }
      );
    },

    loginFailed(response) {
      this.loginInProgress = false;
      this.snackbar.color = "red";
      this.snackbar.text = response.message;
      this.snackbar.visible = true;
    },

    post_Login() {
      return this.$store.dispatch("login/loginUser", { username: this.loginForm.username, password: this.loginForm.password })
    }
  }
};
</script>

<style></style>
