<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <h1 class="text-h3">MGM - Gebühren</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>Exportieren</v-card-title>

          <v-card-text>
            <v-row fluid>              
              <v-col cols="12" sm="3">
                <v-menu
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="billingPeriod"
                      label="Monat"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="billingPeriod"
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>

          <v-divider class="pb-4"></v-divider>

          <v-card-actions class="pt-0 pr-4 pb-4 pl-4">
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="exportButtonDisabled" @click="exportBilling()">
              Exportieren <v-icon right>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      transition="scroll-x-reverse-transition"
      top
      right
      >{{ snackbar.text }}</v-snackbar
    >

  </v-container>
</template>

<script>

import snackbar from '../mixins/snackbar'
import tabTitle from '../mixins/tabTitle';
import { saveAs } from 'file-saver';

export default {

  name: "Billing",
  mixins: [snackbar, tabTitle],
  props: ["title"],

  data() {

    return {

      loading: false,
      billingPeriod: "",
    };
  },

  computed: {

    exportButtonDisabled () {

      return !this.billingPeriod.length > 0
    }
  },

  methods: {

    exportBilling () {

      let splitPeriod = this.billingPeriod.split('-');
      this.loading = true;
      this.$store.dispatch("billings/getBillings", {
        path: "seller",
        year: splitPeriod[0],
        month: splitPeriod[1]
      }).then((file) => {

        saveAs(file,"verkäufer-abrechnung-" + this.billingPeriod + ".csv");
        this.showSnackbar("green", "Gebühren wurden erfolgreich exportiert.");
        this.loading = false;
      }, () => {

        this.showSnackbar("red", "Beim Exportieren der Gebühren ist ein Fehler aufgetreten.");
        this.loading = false;
      })
    },
  }
};
</script>