import axios from 'axios'
import { baseConfig } from '../../config.js';

const state = {

    searchList: [],
    completeSearchCount: 0,
    emptySearch: {
        store_id: "",
        target: "",
        subject: "",
        points: null,
        expiration: null
    },
    currentSearch: {}
};

const getters = {

    searchList (state) {

        return state.searchList;
    },

    maxSearchTableData (state) {

        return state.completeSearchCount;        
    },

    emptySearch (state) {

        return state.emptySearch;
    },

    currentSearch (state) {

        return state.currentSearch;
    }
};

const mutations = {

    setSearchList (state, list) {
      
        state.searchList = list;
    },

    setCompleteSearchCount (state, count) {

        state.completeSearchCount = count;
    },

    setCurrentSearch (state, search) {
        
        search.expiration = search.expiration.slice(0, search.expiration.indexOf("T"));
        state.currentSearch = search;
    },
};

const actions = {
    
    getSearchList (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.search + payload.path, {
                params: {
                    "offset": payload.offset,
                    "count": payload.count,
                    "filter": payload.filter,
                    "sort_desc": payload.sort_desc
                }
            }).then((res) => {

                context.commit("setSearchList", res.data.boosts);
                resolve();
            }, () => {
    
                reject();
            });
        });
    },

    getCompleteSearchCount (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.search + payload.path, {
                params: {
                    "filter": payload.filter
                }
            }).then((res) => {

                context.commit("setCompleteSearchCount", res.data)
                resolve();
            }, () => {

                reject();
            })
        })
    },

    getSearch (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.search + payload.path, {
                params: {
                    "id": payload.id
                }
            })
            .then((res) => {

                context.commit("setCurrentSearch", res.data);
                resolve();
            }, () => {
    
                reject();
            });
        });        
    },
    
    createSearch (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.post(baseConfig.baseUrl + baseConfig.specificUrlPath.search + payload.path, payload.search)
            .then((res) => {

                resolve(res.data);
            }, () => {
    
                reject();
            });
        });        
    },

    updateSearch (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.post(baseConfig.baseUrl + baseConfig.specificUrlPath.search + payload.path, payload.search, {
                params: {
                    "id": payload.id
                },
            })
            .then(() => {

                resolve();
            }, () => {

                reject();
            });
        });
    },

    deleteSearch (context, payload) {
        
        return new Promise ((resolve, reject) => {

            axios.delete(baseConfig.baseUrl + baseConfig.specificUrlPath.search + payload.path, {
                params: {
                    "id": payload.id
                }
            }).then(() => {
    
                resolve();
            }, () => {
    
                reject();
            });
        });
    }
};

export default {

    namespaced: true,
    state,
    getters,
    mutations,
    actions
}