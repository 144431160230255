<template>
  <router-view :navTree="navTree"/>
</template>

<script>
export default {
  name: "App",

  data: () => {
    return {

      navTree: {
        stores: {
          id: 1,
          relatedComponent: "Main",
          relatedView: "stores",
          label: "Märkte",
          icon: "mdi-store",
        },
        seller: {
          id: 3,
          relatedComponent: "Main",
          relatedView: "sellers",
          label: "Verkäufer",
          icon: "mdi-account-group",
        },
        billing: {
          id: 0,
          relatedComponent: "Main",
          relatedView: "billings",
          label: "Gebühren",
          icon: "mdi-currency-eur",
        },
        advertisement: {
          id: 4,
          relatedComponent: "Main",
          relatedView: "advertisements",
          label: "Werbung",
          icon: "mdi-bullhorn",
        },
        search: {
          id: 2,
          relatedComponent: "Main",
          relatedView: "search",
          label: "Suchrangfolge",
          icon: "mdi-magnify",
        },
        login: {
          id: 5,
          relatedComponent: "Login",
          label: "Abmelden",
          icon: "mdi-lock",
        },
      },
    }
  },
}
</script>

<style>
@import "./assets/css/main.css";
</style>
