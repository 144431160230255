<template>
  <v-app>
    <Navigation :navTree="navTree"/>

    <v-main>
      <Billing v-if="$route.params.view === navTree.billing.relatedView" :title="navTree.billing.label" />
      <Stores v-if="$route.params.view === navTree.stores.relatedView"
              :title="navTree.stores.label"
              @storeFormChanges="onFormChanges" />
      <Search v-if="$route.params.view === navTree.search.relatedView"
              :title="navTree.search.label"
              @searchFormChanges="onFormChanges" />
      <Seller v-if="$route.params.view === navTree.seller.relatedView" :title="navTree.seller.label" />
      <Advertisement v-if="$route.params.view === navTree.advertisement.relatedView"
                     :title="navTree.advertisement.label"
                     @advertisementFormChanges="onFormChanges" />
    </v-main>

    <popup
      :popupDialogVisible="securityQuestionDialogVisible"
      popupTitle="Ungespeicherte Daten"
      popupText="Ungespeicherte Daten vorhanden. Wollen Sie die Änderungen verwerfen?"
      rejectedPopupButtonText="Nein"
      acceptedPopupButtonText="Ja"
      v-on:onRejected="rejected()"
      v-on:onAccepted="accepted()">
    </popup>

  </v-app>
</template>

<script>

import Popup from "../components/Popup.vue";
import Navigation from "../components/Navigation.vue";
import Billing from "../components/Billing.vue";
import Stores from "../components/Stores.vue";
import Seller from "../components/Seller.vue";
import Search from "../components/Search.vue";
import Advertisement from "../components/Advertisement.vue";

export default {
  name: "Main",
  components: {
    Navigation,
    Billing,
    Stores,
    Seller,
    Search,
    Advertisement,
    Popup
  },
  props: ["navTree"],

  data: () => {

    return {

      formChanges: false,
      securityQuestionDialogVisible: false,
      targetRoute: {}
    }
  },

  methods: {

    onFormChanges (formChanges) {

      this.formChanges = formChanges;
    },

    accepted () {

      this.securityQuestionDialogVisible = false;
      this.formChanges = false;
      this.$router.push(this.targetRoute);
    },

    rejected () {

      this.securityQuestionDialogVisible = false;
    }
  },

  beforeRouteUpdate  (to, from, next) {

    if (this.formChanges) {

      this.securityQuestionDialogVisible = true;
      this.targetRoute = to;
    } else {

      next();
    }
  }
};
</script>
