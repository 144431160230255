ONBF!NFIGO<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <h1 class="text-h3">MGM - Werbung</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!editDialogVisible">
        <v-card :loading="exportLoading">
          <v-card-title>Exportieren</v-card-title>

          <v-card-text>
            <v-row fluid>
              <v-col cols="12" sm="3">
                <v-menu
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromAdPeriod"
                      label="Von"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="fromAdPeriod"
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="3">
                <v-menu
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toAdPeriod"
                      label="Bis"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="toAdPeriod"
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="pb-4"></v-divider>

          <v-card-actions class="pt-0 pr-4 pb-4 pl-4">
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="exportButtonDisabled" @click="exportAdvertisements()">
              Exportieren <v-icon right>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!editDialogVisible">
        <v-data-table
          :headers="advertisementTableHeader"
          :footer-props="advertisementTableFooter"
          :items="advertisementTableData"
          :server-items-length="maxAdvertisementTableData"
          :options.sync="advertisementTableOptions"
          :loading="loading"
          must-sort
          @update:options="updateTableOptions"
          class="elevation-2"
        >

          <template v-slot:[`item.name`]="{ item }">
            <span @click="showEditDialog(item)" :style="{ cursor: 'pointer'}"> {{item.name}} </span>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="filterText"
                label="Werbepartner filtern"
                single-line
                hide-details
                @input="onFilterChanged"
              ></v-text-field>
                            
              <v-divider class="ml-4" inset vertical></v-divider>

              <v-btn icon tile @click="showEditDialog()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>                
            </v-toolbar>           
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showEditDialog(item)">mdi-pencil</v-icon>
            <v-icon small @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>

          <template v-slot:no-data>Keine Werbepartner gefunden</template>
        </v-data-table>
      </v-col>

      <v-col v-if="editDialogVisible">
        <v-form ref="formAdvertisement">
          <v-card :loading="loading">
            <v-toolbar flat v-if="!loading">
              <v-toolbar-title>{{ editDialogHeadline }}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon tile @click="closeEditDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-divider></v-divider>

            <v-card-text v-if="!loading">
              <v-container>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Name *"
                      v-model="selectedAdvertisementEntry.name"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="4">
                    <v-card width="320" @click="cropperImageSmallVisible = true">
                      <v-card-title :class="{invalid: invalidImageInput.bannerSmall}">Klein *</v-card-title>

                      <v-divider></v-divider>

                      <div class="d-flex justify-center" style="width: 320px; height: 180px;">
                        <v-img
                          aspect-ratio="16/9"
                          width="320"
                          height="180"
                          contain
                          :src="selectedAdvertisementEntry.banner_small.image_data"
                          v-if="selectedAdvertisementEntry.banner_small.image_data"
                        ></v-img>

                        <v-icon size="72" v-if="!selectedAdvertisementEntry.banner_small.image_data">
                          mdi-image-plus
                        </v-icon>
                      </div>
                    </v-card>
                    <v-btn
                      small
                      :disabled="!selectedAdvertisementEntry.banner_small.image_data"
                      @click="imageLinkClicked(selectedAdvertisementEntry.banner_small.image_data)"
                      class="my-4">
                      Vollbildansicht
                    </v-btn>
                    <v-text-field
                      label="Link App *"
                      v-model="selectedAdvertisementEntry.banner_small.banner_link"
                      :rules="[urlRule]"
                      required>
                      <v-tooltip slot="append" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-alert-circle</v-icon>
                        </template>                          
                        <span>Eingabe einer gültigen URL (Bsp.: http://example/url)</span>
                      </v-tooltip>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" lg="4">
                    <v-card width="320" @click="cropperImageMediumVisible = true">
                      <v-card-title :class="{invalid: invalidImageInput.bannerMedium}">Mittel *</v-card-title>

                      <v-divider></v-divider>

                      <div class="d-flex justify-center" style="width: 320px; height: 180px;">
                        <v-img
                          aspect-ratio="16/9"
                          width="320"
                          height="180"
                          contain
                          :src="selectedAdvertisementEntry.banner_medium.image_data"
                          v-if="selectedAdvertisementEntry.banner_medium.image_data"
                        ></v-img>

                        <v-icon size="72" v-if="!selectedAdvertisementEntry.banner_medium.image_data">
                          mdi-image-plus
                        </v-icon>
                      </div>
                    </v-card>
                    <v-btn
                      small
                      :disabled="!selectedAdvertisementEntry.banner_medium.image_data"
                      @click="imageLinkClicked(selectedAdvertisementEntry.banner_medium.image_data)"
                      class="my-4">
                      Vollbildansicht
                    </v-btn>
                    <v-text-field
                      label="Link App *"
                      v-model="selectedAdvertisementEntry.banner_medium.banner_link"
                      :rules="[urlRule]"
                      required>
                      <v-tooltip slot="append" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-alert-circle</v-icon>
                        </template>                          
                        <span>Eingabe einer gültigen URL (Bsp.: http://example/url)</span>
                      </v-tooltip>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" lg="4">
                    <v-card width="320" @click="cropperImageLargeVisible = true">
                      <v-card-title :class="{invalid: invalidImageInput.bannerLarge}">Groß *</v-card-title>

                      <v-divider></v-divider>

                      <div class="d-flex justify-center" style="width: 320px; height: 180px;">
                        <v-img
                          aspect-ratio="16/9"
                          width="320"
                          height="180"
                          contain
                          :src="selectedAdvertisementEntry.banner_large.image_data"
                          v-if="selectedAdvertisementEntry.banner_large.image_data"
                        ></v-img>

                        <v-icon size="72" v-if="!selectedAdvertisementEntry.banner_large.image_data">
                          mdi-image-plus
                        </v-icon>
                      </div>
                    </v-card>
                    <v-btn
                      small
                      :disabled="!selectedAdvertisementEntry.banner_large.image_data"
                      @click="imageLinkClicked(selectedAdvertisementEntry.banner_large.image_data)"
                      class="my-4">
                      Vollbildansicht
                    </v-btn>
                    <v-text-field
                      label="Link App *"
                      v-model="selectedAdvertisementEntry.banner_large.banner_link"
                      :rules="[urlRule]"
                      required>
                      <v-tooltip slot="append" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-alert-circle</v-icon>
                        </template>                          
                        <span>Eingabe einer gültigen URL (Bsp.: http://example/url)</span>
                      </v-tooltip>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider class="pb-4"></v-divider>

            <v-card-actions class="pt-0 pr-4 pb-4 pl-4">
              <span class="text-caption"> * Pflichtfelder </span>
              <v-spacer></v-spacer>

              <v-btn @click="closeEditDialog()">Zurück</v-btn>

              <v-btn color="primary" @click="saveAdvertisement()">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <popup
      :popupDialogVisible="deleteDialogVisible"
      popupTitle="Werbepartner löschen"
      :popupText="deleteDialogText"
      rejectedPopupButtonText="Abbrechen"
      acceptedPopupButtonText="Löschen"
      v-on:onRejected="closeDeleteDialog()"
      v-on:onAccepted="deleteAdvertisement()">
    </popup>

    <cropper-dialog
      :cropperDialogVisible="cropperImageSmallVisible"
      v-on:saveCroppedFile="saveCropperSmallFile"
      v-on:closeCropperDialog="cropperImageSmallVisible = false">
    </cropper-dialog>

    <cropper-dialog
      :cropperDialogVisible="cropperImageMediumVisible"
      v-on:saveCroppedFile="saveCropperMediumFile"
      v-on:closeCropperDialog="cropperImageMediumVisible = false">
    </cropper-dialog>

    <cropper-dialog
      :cropperDialogVisible="cropperImageLargeVisible"
      v-on:saveCroppedFile="saveCropperLargeFile"
      v-on:closeCropperDialog="cropperImageLargeVisible = false">
    </cropper-dialog>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      transition="scroll-x-reverse-transition"
      top
      right
    >{{ snackbar.text }}</v-snackbar>
  </v-container>
</template>

<script>

import CropperDialog from "./CropperDialog.vue";
import Popup from "./Popup.vue";
import imageToBase64 from 'image-to-base64/browser';
import snackbar from '../mixins/snackbar';
import tabTitle from '../mixins/tabTitle';

export default {
  
  name: "Advertisement",
  mixins: [snackbar, tabTitle],
  components: { CropperDialog, Popup },
  props: ["title"],

  data: () => {

    return {      
      
      loading: true,
      exportLoading: false,
      filterText: "",
      fromAdPeriod: "",
      toAdPeriod: "",
      deleteDialogVisible: false,
      selectedAdvertisementEntry: {},
      advertisementTableHeader: [
        {
          text: "Werbepartner",
          value: "name",   
        },
        {
          text: "Aktionen",
          value: "actions",
          sortable: false,
          width: "100px",
        }
      ],
      advertisementTableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: [false]
      },
      advertisementTableFooter: {
        "items-per-page-options": [10,20,50]
      },
      cropperImageSmallVisible: false,
      cropperImageMediumVisible: false,
      cropperImageLargeVisible: false,

      invalidImageInput: {
        bannerSmall: false,
        bannerMedium: false,
        bannerLarge: false
      },
      urlRule: v => {

        if (!v) {
          return false
        } else if ( !(v.startsWith("http:") || v.startsWith("https:")) ) {
          return  'Eingabewert muss mit "http:" oder "https:" anfangen'
        } else {
          return true
        }
      }
    };
  },
  
  created () {

    this.selectedAdvertisementEntry = JSON.parse(JSON.stringify(this.$store.getters["advertisements/emptyAdvertisement"]));
    if (this.$route.query.advertisement) {

      if (this.$route.query.advertisement !== "new") {

        this.updateSelectedAdvertisement(this.$route.query.advertisement).then(() => {
          
          this.loading = false;
        }, () => {

          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
          this.loading = false;
        })        
      } else {

        this.loading = false;
      }
    }      
  },

  computed: {

    advertisementTableData () {

      return this.$store.getters["advertisements/advertisementList"];
    },

    maxAdvertisementTableData () {

      return this.$store.getters["advertisements/maxAdvertisementTableData"];
    },

    editDialogVisible () {

      return (this.$route.query.advertisement ? true : false);
    },

    editDialogHeadline () {

      if (this.$route.query.advertisement === "new") {
        
        return "Neuer Werbepartner"
      } else {

        return this.selectedAdvertisementEntry.name
      };
    },

    exportButtonDisabled () {

      return (!this.fromAdPeriod.length > 0) || (!this.toAdPeriod > 0)
    },

    deleteDialogText () {

      return 'Wollen Sie den Werbepartner "' + this.selectedAdvertisementEntry.name + '" wirklich löschen?'
    }
  },

  watch: {

    $route (to,from ) {

      this.invalidImageInput.bannerSmall = false;
      this.invalidImageInput.bannerMedium = false;
      this.invalidImageInput.bannerLarge = false;
    },

    selectedAdvertisementEntry: {

      deep: true,
      handler () {

        if (this.$refs.formAdvertisement) {

          this.$emit("advertisementFormChanges", true);
        }
      }
    }
  },

  methods: {

    exportAdvertisements () {

      let splitPeriodFrom = this.fromAdPeriod.split('-');
      let splitPeriodTo = this.toAdPeriod.split('-');
      this.exportLoading = true;
      this.$store.dispatch("advertisements/getExportAdvertisements", {
        path: "advertisement",
        start_year: splitPeriodFrom[0],
        start_month: splitPeriodFrom[1],
        end_year: splitPeriodTo[0],
        end_month: splitPeriodTo[1]
      }).then((file) => {

        saveAs(file,"werbung-abrechnung-" + this.fromAdPeriod + "_" + this.toAdPeriod + ".csv");
        this.showSnackbar("green", "Gebühren wurden erfolgreich exportiert.");
        this.exportLoading = false;
      }, () => {

        this.showSnackbar("red", "Beim Exportieren der Werbepartner ist ein Fehler aufgetreten.");
      })
    },

    onFilterChanged () {
      
      this.advertisementTableOptions.page = 1;
      this.updateTableOptions();
    },

    updateTableOptions () {

      this.loading = true;
      this.updateTable(this.advertisementTableOptions.page - 1, this.advertisementTableOptions.itemsPerPage, this.advertisementTableOptions.sortDesc[0], this.filterText)
      .then(() => {

        this.loading = false;
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        this.loading = false;
      })
    },

    updateTable (offset, count, sort_desc, filter) {

      return new Promise ((resolve, reject) => {

        this.$store.dispatch("advertisements/getAdvertisementList", {
          path: "list",
          offset,
          count,
          filter,
          sort_desc
        }).then(() => {
          this.$store.dispatch("advertisements/getCompleteAdvertisementCount", {
            path: "count",
            filter
            }).then(() => resolve(), () => reject());
        }, () => reject());
      })      
    },

    updateSelectedAdvertisement (advertisementId) {
      
      return new Promise ((resolve, reject) => {

        this.$store.dispatch("advertisements/getAdvertisement", {
          path: "info",
          id: advertisementId
        }).then(() => {

          this.selectedAdvertisementEntry = JSON.parse(JSON.stringify(this.$store.getters["advertisements/currentAdvertisement"]));
          Promise.all([
            imageToBase64(this.selectedAdvertisementEntry.banner_small.image_url),
            imageToBase64(this.selectedAdvertisementEntry.banner_medium.image_url),
            imageToBase64(this.selectedAdvertisementEntry.banner_large.image_url)
          ])
          .then((responses) => {

            this.$set(this.selectedAdvertisementEntry.banner_small, 'image_data', "data:image/png;base64," + responses[0]);
            this.$set(this.selectedAdvertisementEntry.banner_medium, 'image_data', "data:image/png;base64," + responses[1]);
            this.$set(this.selectedAdvertisementEntry.banner_large, 'image_data', "data:image/png;base64," + responses[2]);
            resolve();
          })
        }, () => {

          reject()
        });
      });      
    },

    showDeleteDialog (advertisement) {

      this.updateSelectedAdvertisement(advertisement.id).then(() => {

        this.deleteDialogVisible = true;
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
      })      
    },

    closeDeleteDialog () {

      this.deleteDialogVisible = false;
    },

    showEditDialog (advertisement) {

      if (advertisement) {

        this.updateSelectedAdvertisement(advertisement.id).then(() => {

          this.$router.push({ query: { advertisement: this.selectedAdvertisementEntry.id } });
        }, () => {

          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        })        
      } else {

        this.selectedAdvertisementEntry = JSON.parse(JSON.stringify(this.$store.getters["advertisements/emptyAdvertisement"]));
        this.$router.push({ query: { advertisement: 'new' } });
      }      
    },

    closeEditDialog () {

      this.$router.push({ query: {} })
    },

    saveAdvertisement () {

      if(!this.$refs.formAdvertisement.validate()) {

        this.showSnackbar("red", "Einige Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie die rot markierten Felder.");
        if (!this.selectedAdvertisementEntry.banner_small.image_data) {

          this.invalidImageInput.bannerSmall = true;
        }
        if (!this.selectedAdvertisementEntry.banner_medium.image_data) {

          this.invalidImageInput.bannerMedium = true;
        }
        if (!this.selectedAdvertisementEntry.banner_large.image_data) {

          this.invalidImageInput.bannerLarge = true;
        }
        return;
      }

      this.$route.query.advertisement === "new" ? this.createAdvertisement() : this.updateAdvertisement()
    },

    updateAdvertisement () {

      this.loading = true;
      this.$store.dispatch("advertisements/updateAdvertisement", {
        path: "update",
        advertisement: this.selectedAdvertisementEntry
      }).then(() => {

        this.showSnackbar("green", "Werbepartner wurde erfolgreich gespeichert.");
        this.updateSelectedAdvertisement(this.selectedAdvertisementEntry.id).then(() => {
          
          this.$emit("advertisementFormChanges", false);
          this.loading = false;
        }, () => {

          this.loading = false;
          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        });
      }, () => {

        this.showSnackbar("red", "Beim Speichern des Werbepartners ist ein Fehler aufgetreten.");
        this.loading = false;
      });
    },

    createAdvertisement () {

      this.loading = true;
      this.$store.dispatch("advertisements/createAdvertisement", {
        path: "create",
        advertisement: this.selectedAdvertisementEntry
      }).then((advertisementId) => {

        this.showSnackbar("green", "Werberpartner wurde erfolgreich gespeichert.");
        this.updateSelectedAdvertisement(advertisementId).then(() => {

          this.$emit("advertisementFormChanges", false);
          this.loading = false;
          this.$router.push({ query: { advertisement: advertisementId } });
        }, () => {

          this.loading = false;
          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        });
      }, () => {

        this.showSnackbar("red", "Beim Speichern des Werbepartners ist ein Fehler aufgetreten.");
        this.loading = false;
      });
    },

    deleteAdvertisement () {

      this.deleteDialogVisible = false;
      this.$store.dispatch("advertisements/deleteAdvertisement", {
        path: "delete",
        id: this.selectedAdvertisementEntry.id
      }).then(() => {

        this.showSnackbar("green", "Werbepartner wurde erfolgreich gelöscht.");
        this.updateTableOptions();
      }, () => {

        this.showSnackbar("red", "Beim Löschen des Werbepartners ist ein Fehler aufgetreten.");
      });      
    },

    saveCropperSmallFile (croppedFileUrl) {

      this.$set(this.selectedAdvertisementEntry.banner_small, 'image_data', croppedFileUrl);
      if (this.selectedAdvertisementEntry.banner_small.image_data) {

        this.invalidImageInput.bannerSmall = false;
      }
    },
    
    saveCropperMediumFile (croppedFileUrl) {

      this.$set(this.selectedAdvertisementEntry.banner_medium, 'image_data', croppedFileUrl);
      if (this.selectedAdvertisementEntry.banner_medium.image_data) {

        this.invalidImageInput.bannerMedium = false;
      }
    },

    saveCropperLargeFile (croppedFileUrl) {
      
      this.$set(this.selectedAdvertisementEntry.banner_large, 'image_data', croppedFileUrl);
      if (this.selectedAdvertisementEntry.banner_large.image_data) {

        this.invalidImageInput.bannerLarge = false;
      }
    },

    imageLinkClicked (file) {

      let newTab = window.open();
      newTab.document.body.innerHTML = `<img src=${file}>`;
    }
  },    
};
</script>

<style scoped>

  .invalid {

    color: red !important
  }

</style>