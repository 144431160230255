import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    store,
    render: function (h) { return h(App) },
    created() {
        this.$store.dispatch("login/setup", { router: router })
    }
}).$mount('#app')
