export default {

    created () {

        if (this.title) {

            document.title = "MGM - " + this.title;
        } else {

            const { title } = this.$options;
            if  (title) {

                document.title = "MGM - " + title;
            }
        }
    }
}