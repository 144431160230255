<template>

    <v-dialog v-model="cropperDialogVisible" @click:outside="resetAndClose()" max-width="600px">
        <v-card>
            <v-card-title>Bild hochladen</v-card-title>

            <v-card-text>
                <v-file-input v-model="file" @change="onFileChanged"> </v-file-input>

                <vue-cropper
                    ref="cropper"
                    alt="Source Image"
                    :src="cropperFileUrl"
                    v-if="cropperFileUrl"
                ></vue-cropper>
            </v-card-text>

            <v-card-actions class="pt-0 pr-4 pb-4 pl-4">
            <v-spacer></v-spacer>

            <v-btn @click="resetAndClose()">Abbrechen</v-btn>

            <v-btn color="primary" @click="save()">Speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
    
    name: "CropperDialog",    
    components: { VueCropper },
    props: ["cropperDialogVisible"],

    data: () => {

        return {

            cropperFileUrl: null,
            file: null
        }
    },

    methods: {

        onFileChanged () {

            if (this.file) {

                if (this.cropperFileUrl) {

                    this.cropperFileUrl = URL.createObjectURL(this.file);
                    this.$refs.cropper.replace(this.cropperFileUrl);
                } else {

                    this.cropperFileUrl = URL.createObjectURL(this.file);
                }
            } else {

                this.cropperFileUrl = null;
            }
        },

        save () {

            if (this.cropperFileUrl) {

                this.$emit("saveCroppedFile", this.$refs.cropper.getCroppedCanvas().toDataURL())
            }

            this.resetAndClose();
        },

        resetAndClose() {
            
            this.$emit("closeCropperDialog");
            this.file = null;
            this.cropperFileUrl = null;
        },
    }
}

</script>