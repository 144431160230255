<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <h1 class="text-h3">MGM - Suchrangfolge</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!editDialogVisible">
        <v-data-table
          :headers="searchTableHeader"
          :footer-props="searchTableFooter"
          :items="searchTableData"
          :server-items-length="maxSearchTableData"
          :options.sync="searchTableOptions"
          :loading="loading"
          must-sort
          @update:options="updateTableOptions"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                label="Märkte durchsuchen"
                single-line
                hide-details
                v-model="filterText"
                @input="onFilterChanged"
              ></v-text-field>

              <v-divider class="ml-4" inset vertical></v-divider>

              <v-btn icon tile @click="showEditDialog()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:[`item.expiration`]="{ item }">
            {{item.expiration.slice(0, item.expiration.indexOf("T"))}}
          </template>

          <template v-slot:[`item.target`]="{ item }">
            {{availableCategories.find(el => el.value === item.target).label}}
          </template>

          <template v-slot:[`item.store_name`]="{ item }">
            <span @click="showEditDialog(item)" :style="{ cursor: 'pointer'}"> {{item.store_name}} </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showEditDialog(item)">mdi-pencil</v-icon>
            <v-icon small @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          
          <template v-slot:no-data>Keine Suchrangfolgen gefunden</template>
        </v-data-table>
      </v-col>

      <v-col v-if="editDialogVisible">
        <v-form ref="formSearch">
          <v-card :loading="loading">
            <v-toolbar flat v-if="!loading">
              <v-toolbar-title>{{ editDialogHeadline }}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon tile @click="closeEditDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-divider></v-divider>

            <v-card-text v-if="!loading">
              <v-container>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-autocomplete
                      v-model="selectedSearchEntry.store_id"
                      :items="availableStores"
                      item-text="name"
                      item-value="id"
                      label="Markt *"
                      :rules="[(v) => !!v]"
                      required
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-autocomplete
                      v-model="selectedSearchEntry.target"
                      :items="availableCategories"
                      item-text="label"
                      item-value="value"
                      label="Ziel *"
                      :rules="[(v) => !!v]"
                      required
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Wert *"
                      v-model="selectedSearchEntry.subject"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Punkte *"
                      v-model.number="selectedSearchEntry.points"
                      :rules="[numberRule]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-menu
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selectedSearchEntry.expiration"
                          label="Ablaufdatum *"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[(v) => !!v]"
                          required
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="selectedSearchEntry.expiration"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider class="pb-4"></v-divider>

            <v-card-actions class="pt-0 pr-4 pb-4 pl-4">
              <span class="text-caption"> * Pflichtfelder </span>
              <v-spacer></v-spacer>

              <v-btn @click="closeEditDialog()">Zurück</v-btn>

              <v-btn color="primary" @click="saveSearch()">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <popup
      :popupDialogVisible="deleteDialogVisible"
      popupTitle="Suchrangfolge löschen"
      :popupText="deleteDialogText"
      rejectedPopupButtonText="Abbrechen"
      acceptedPopupButtonText="Löschen"
      v-on:onRejected="closeDeleteDialog()"
      v-on:onAccepted="deleteSearch()">
    </popup>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      transition="scroll-x-reverse-transition"
      top
      right
      >{{ snackbar.text }}</v-snackbar
    >      
  </v-container>
</template>

<script>

import Popup from "./Popup.vue";
import snackbar from '../mixins/snackbar';
import tabTitle from '../mixins/tabTitle';

export default {

  name: "Search",
  mixins: [snackbar, tabTitle],
  components: { Popup },
  props: ["title"],

  data: () => {

    return {

      loading: true,
      filterText: "",
      deleteDialogVisible: false,
      selectedSearchEntry: {},
      searchTableHeader: [
        {
          text: "Markt",
          value: "store_name",        
        },
        {
          text: "Ziel",
          value: "target",
          sortable: false,
        },
        {
          text: "Wert",
          value: "subject",
          sortable: false,
        },
        {
          text: "Punkte",
          value: "points",
          sortable: false,
        },
        {
          text: "Ablaufdatum",
          value: "expiration",
          sortable: false,
        },
        {
          text: "Aktionen",
          value: "actions",
          sortable: false,
          width: "100px",
        }
      ],
      searchTableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: [false]
      },
      searchTableFooter: {
        "items-per-page-options": [10,20,50]
      },
      availableCategories: [
        {
          label: "Kategorie",
          value: "category"
        },
        {
          label: "Marktname",
          value: "store"
        },
        {
          label: "Stadt",
          value: "city"
        }
      ],
      numberRule: v => {
        if (!v) {
          return false
        }
        else if (!Number.isInteger(v)) {
          return "Eingabewert muss eine ganze Zahl sein."
        } else {
          return true
        }
      }
    };    
  },
  
  created () {

    this.$store.dispatch("stores/getStoreListSelection", {
      path: "selection",
      filter: ""
      }).then((res) => {

      this.selectedSearchEntry = JSON.parse(JSON.stringify(this.$store.getters["searchRankings/emptySearch"]));
      if (this.$route.query.searchRanking) {

        if (this.$route.query.searchRanking !== "new") {

          this.updateSelectedSearch(this.$route.query.searchRanking).then(() => {
            
            this.loading = false;
          }, () => {

            this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
            this.loading = false;
          })        
        } else {

          this.loading = false;
        }
      }
    }, () => {

      this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
      this.loading = false;
    });          
  },

  computed: {

    searchTableData () {

      return this.$store.getters["searchRankings/searchList"];
    },

    maxSearchTableData () {

      return this.$store.getters["searchRankings/maxSearchTableData"];
    },

    editDialogVisible () {

      return (this.$route.query.searchRanking ? true : false);
    },
    editDialogHeadline () {

      if (this.$route.query.searchRanking === "new") {
        
        return "Neue Suchrangfolge";
      } else {

        return "Suchrangfolge für " + this.searchTitle;
      };
    },

    availableStores () {

      return this.$store.getters["stores/storeListSelection"];
    },
    
    searchTitle () {

      return this.$store.getters["stores/storeNameSelectionById"](this.selectedSearchEntry.store_id);
    },

    deleteDialogText () {

      return 'Wollen Sie die Suchrangfolge mit dem Wert "' + this.selectedSearchEntry.subject +
             '" für den Markt "' + this.selectedSearchEntry.store_name + '" wirklich löschen?'
    }
  },

  watch: {

    selectedSearchEntry: {

      deep: true,
      handler () {

          if (this.$refs.formSearch) {

            this.$emit("searchFormChanges", true);
          }
      }
    }
  },

  methods: {

    onFilterChanged () {
      
      this.searchTableOptions.page = 1;
      this.updateTableOptions();
    },

    updateTableOptions () {

      this.loading = true;
      this.updateTable(this.searchTableOptions.page - 1, this.searchTableOptions.itemsPerPage, this.searchTableOptions.sortDesc[0], this.filterText)
      .then(() => {

        this.loading = false;
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        this.loading = false;
      })
    },

    updateTable (offset, count, sort_desc, filter) {

      return new Promise ((resolve, reject) => {

        this.$store.dispatch("searchRankings/getSearchList", {
          path: "list",
          offset,
          count,
          filter,
          sort_desc
        }).then(() => {
          this.$store.dispatch("searchRankings/getCompleteSearchCount", {
            path: "count",
            filter
            }).then(() => resolve(), () => reject());
        }, () => reject());
      });
    },
    
    updateSelectedSearch (searchId) {
      
      return new Promise ((resolve, reject) => {

        this.$store.dispatch("searchRankings/getSearch", {
          path: "info",
          id: searchId
        }).then(() => {

          this.selectedSearchEntry = JSON.parse(JSON.stringify(this.$store.getters["searchRankings/currentSearch"]));
          resolve();
        }, () => {

          reject()
        });
      });      
    },

    showDeleteDialog (search) {

      this.updateSelectedSearch(search.id).then(() => {

        this.deleteDialogVisible = true;
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
      })      
    },

    closeDeleteDialog () {

      this.deleteDialogVisible = false;
    },

    showEditDialog (search) {

      if (search) {

        this.updateSelectedSearch(search.id).then(() => {

          this.$router.push({ query: { searchRanking: this.selectedSearchEntry.id } });
        }, () => {

          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        })        
      } else {

        this.selectedSearchEntry = JSON.parse(JSON.stringify(this.$store.getters["searchRankings/emptySearch"]));
        this.$router.push({ query: { searchRanking: 'new' } });
      }
    },

    closeEditDialog () {

      this.$router.push({ query: {} })
    },

    saveSearch () {

      if (!this.$refs.formSearch.validate()) {

        this.showSnackbar("red", "Einige Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie die rot markierten Felder.");
        return;
      }

      this.$route.query.searchRanking === "new" ? this.createSearch() : this.updateSearch()
    },

    updateSearch () {

      this.loading = true;
      this.$store.dispatch("searchRankings/updateSearch", {
        path: "update",
        search: this.selectedSearchEntry
      }).then(() => {

        this.showSnackbar("green", "Suchrangfolge wurde erfolgreich gespeichert.");
        this.updateSelectedSearch(this.selectedSearchEntry.id).then(() => {

          this.$emit("searchFormChanges", false);
          this.loading = false;
        }, () => {

          this.loading = false;
          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        });        
      }, () => {

        this.showSnackbar("red", "Beim Speichern der Suchrangfolge ist ein Fehler aufgetreten.");
        this.loading = false;
      });
    },

    createSearch () {

      this.loading = true;
      this.$store.dispatch("searchRankings/createSearch", {
        path: "create",
        search: this.selectedSearchEntry
      }).then((searchId) => {

        this.showSnackbar("green", "Suchrangfolge wurde erfolgreich gespeichert.");
        this.updateSelectedSearch(searchId).then(() => {

          this.$emit("searchFormChanges", false);
          this.loading = false;
          this.$router.push({ query: { searchRanking: searchId } });
        }, () => {
          
          this.loading = false;
          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        });
      }, () => {

        this.showSnackbar("red", "Beim Speichern der Suchrangfolge ist ein Fehler aufgetreten.");
        this.loading = false;
      });
    },

    deleteSearch () {

      this.deleteDialogVisible = false;
      this.$store.dispatch("searchRankings/deleteSearch", {
        path: "delete",
        id: this.selectedSearchEntry.id
      }).then(() => {

        this.showSnackbar("green", "Suchrangfolge wurde erfolgreich gelöscht.");
        this.updateTableOptions();
      }, () => {

        this.showSnackbar("red", "Beim Löschen der Suchrangfolge ist ein Fehler aufgetreten.");
      });      
    },
  }
};
</script>
