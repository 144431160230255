import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {

            if (!to.params.view && from.params.view) {

                store.dispatch("login/sessionCheck")
                .then(() => {

                    store.commit("login/setShowLogoutDialog", true);
                    next(false);
                }, () => {

                    next();
                })
            } else {

                next();
            }
        }
    },
    {
        path: '/:view',
        name: 'Main',
        component: Main,
        beforeEnter: (to, from, next) => store.dispatch("login/sessionCheck").then(() => next(), () => next('/'))
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
