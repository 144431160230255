<template>
  <v-container class="pa-8">
    <v-row>
      <v-col>
        <h1 class="text-h3">MGM - Märkte</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!editDialogVisible">
        <v-data-table
          :headers="storeTableHeader"
          :footer-props="storeTableFooter"
          :items="storeTableData"
          :server-items-length="maxStoreTableData"
          :options.sync="storeTableOptions"
          :loading="loading"
          must-sort
          @update:options="updateTableOptions"
          class="elevation-2"
        >

          <template v-slot:[`item.name`]="{ item }">
            <span @click="showEditDialog(item)" :style="{ cursor: 'pointer'}"> {{item.name}} </span>
          </template>

          <template v-slot:[`item.logo_image_url`]="{ item }">
            <v-img
              aspect-ratio="16/9"
              width="120"
              height="50"
              contain
              :src="item.logo_image_url"
            ></v-img>
          </template>
          
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showEditDialog(item)">mdi-pencil</v-icon>
            <v-icon small @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                label="Märkte durchsuchen"
                single-line
                hide-details
                v-model="filterText"
                @input="onFilterChanged"
              ></v-text-field>

              <v-divider class="ml-4" inset vertical></v-divider>

              <v-btn icon tile @click="showEditDialog()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:no-data>Keine Märkte gefunden</template>
        </v-data-table>
      </v-col>

      <v-col v-if="editDialogVisible">
        <v-form ref="formStore">
          <v-card :loading="loading">
            <v-toolbar flat v-if="!loading">
              <v-toolbar-title>{{ editDialogHeadline }}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon tile @click="closeEditDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-divider></v-divider>

            <v-card-text v-if="!loading">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-subheader class="pa-0">Stammdaten</v-subheader>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Marktname *"
                      v-model="selectedStoreEntry.name"                      
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Begrüßungstext Überschrift *"
                      v-model="selectedStoreEntry.greeting_headline"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-textarea
                      label="Begrüßungstext *"
                      v-model="selectedStoreEntry.greeting_text"
                      :rules="[(v) => !!v]"
                      required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-textarea
                      label="Gebühreninformation *"
                      v-model="selectedStoreEntry.fee_info_text"
                      :rules="[(v) => !!v]"
                      required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-textarea
                      label="Kategorien *"
                      v-model="selectedStoreEntry.categories"
                      :rules="[(v) => !!v]"
                      required>
                      <v-tooltip slot="append" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-alert-circle</v-icon>
                        </template>                          
                        <span>Kategorien werden mit einem Komma getrennt (Bsp.: Kategorie eins,Kategorie zwei,Kategorie drei)</span>
                      </v-tooltip>
                    </v-textarea>
                  </v-col>

                  <v-col cols="12" sm="10" class="py-0">
                    <v-text-field
                      label="Verkäufercode *"
                      v-model="selectedStoreEntry.seller_invite_code"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="2" class="py-0">
                    <v-checkbox
                      label="Markt aktiv"
                      v-model="selectedStoreEntry.active"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" sm="12" class="py-0">
                    <v-text-field
                      label="Link zum Markt *"
                      v-model="selectedStoreEntry.web_shop_link_url "
                      :rules="[urlRule]"
                      required>
                      <v-tooltip slot="append" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-alert-circle</v-icon>
                        </template>                          
                        <span>Eingabe einer gültigen URL (Bsp.: http://example/url)</span>
                      </v-tooltip>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" class="py-0">
                    <v-text-field
                      label="Linktext in der App *"
                      v-model="selectedStoreEntry.web_shop_link_text "
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pa-0">
                    <v-subheader class="pa-0">Marktadresse / Marktkontakt</v-subheader>
                  </v-col>

                  <v-col cols="12" sm="10" class="py-0">
                    <v-text-field
                      label="Straße *"
                      v-model="selectedStoreEntry.street_name"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="2" class="py-0">
                    <v-text-field
                      label="Hausnummer *"
                      v-model="selectedStoreEntry.house_number"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="Postleitzahl *"
                      v-model="selectedStoreEntry.zip_code"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8" class="py-0">
                    <v-text-field
                      label="Stadt *"
                      v-model="selectedStoreEntry.city"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field
                      label="Telefon *"
                      v-model="selectedStoreEntry.phone"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field
                      label="Fax *"
                      v-model="selectedStoreEntry.fax"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8" class="py-0">
                    <v-text-field
                      label="Email *"
                      v-model="selectedStoreEntry.email"
                      :rules="[(v) => !!v]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pa-0">
                    <v-subheader class="pa-0">Bildmaterial</v-subheader>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-card width="320" @click="cropperLogoVisible = true">
                      <v-card-title :class="{invalid: invalidImageInput.logo}">Logo *</v-card-title>

                      <v-divider></v-divider>

                      <div class="d-flex justify-center" style="width: 320px; height: 180px;">
                        <v-img
                          aspect-ratio="16/9"
                          width="320"
                          height="180"
                          contain
                          :src="selectedStoreEntry.logo_image"
                          v-if="selectedStoreEntry.logo_image"
                        ></v-img>

                        <v-icon size="72" v-if="!selectedStoreEntry.logo_image">
                          mdi-image-plus
                        </v-icon>                        
                      </div>
                    </v-card>
                    <v-btn
                      small
                      :disabled="!selectedStoreEntry.logo_image"
                      @click="imageLinkClicked(selectedStoreEntry.logo_image)"
                      class="my-4">
                      Vollbildansicht
                    </v-btn>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-card width="320" @click="cropperGreetingVisible = true">
                      <v-card-title :class="{invalid: invalidImageInput.greeting}">Begrüßungsbild *</v-card-title>

                      <v-divider></v-divider>

                      <div class="d-flex justify-center" style="width: 320px; height: 180px;">
                        <v-img
                          aspect-ratio="16/9"
                          width="320"
                          height="180"
                          contain
                          :src="selectedStoreEntry.greeting_image"
                          v-if="selectedStoreEntry.greeting_image"
                        ></v-img>

                        <v-icon size="72" v-if="!selectedStoreEntry.greeting_image">
                          mdi-image-plus
                        </v-icon>
                      </div>
                    </v-card>
                    <v-btn
                      small
                      :disabled="!selectedStoreEntry.greeting_image"
                      @click="imageLinkClicked(selectedStoreEntry.greeting_image)"
                      class="my-4">
                      Vollbildansicht
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider class="pb-4"></v-divider>

            <v-card-actions class="pt-0 pr-4 pb-4 pl-4">
              <span class="text-caption"> * Pflichtfelder </span>
              <v-spacer></v-spacer>

              <v-btn @click="closeEditDialog()">Zurück</v-btn>

              <v-btn color="primary" @click="saveStore()">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>

      <popup
        :popupDialogVisible="deleteDialogVisible"
        popupTitle="Markt löschen"
        :popupText="deleteDialogText"
        rejectedPopupButtonText="Abbrechen"
        acceptedPopupButtonText="Löschen"
        v-on:onRejected="closeDeleteDialog()"
        v-on:onAccepted="deleteStore()">
      </popup>

      <cropper-dialog
        :cropperDialogVisible="cropperLogoVisible"
        v-on:saveCroppedFile="saveCropperLogoFile"
        v-on:closeCropperDialog="cropperLogoVisible = false">
      </cropper-dialog>

      <cropper-dialog
        :cropperDialogVisible="cropperGreetingVisible"
        v-on:saveCroppedFile="saveCropperGreetingFile"
        v-on:closeCropperDialog="cropperGreetingVisible = false">
      </cropper-dialog>

      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        transition="scroll-x-reverse-transition"
        top
        right
        >{{ snackbar.text }}</v-snackbar
      >
    </v-row>
  </v-container>
</template>

<script>

import CropperDialog from "./CropperDialog.vue";
import Popup from "./Popup.vue";
import imageToBase64 from 'image-to-base64/browser';
import snackbar from '../mixins/snackbar';
import tabTitle from '../mixins/tabTitle';

export default {

  name: "Stores",
  mixins: [snackbar, tabTitle],
  components: { CropperDialog, Popup },
  props: ["title"],

  data: () => {

    return {

      loading: true,
      filterText: "",
      deleteDialogVisible: false,
      selectedStoreEntry: {},
      storeTableHeader: [
        {
          text: "Markt",
          value: "name",        
        },
        {
          text: "Stadt",
          value: "city",
          sortable: false,
        },
        {
          text: "Logo",
          value: "logo_image_url",
          sortable: false,
        },
        {
          text: "Aktionen",
          value: "actions",
          sortable: false,
          width: "100px",
        }
      ],
      storeTableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: [false]
      },
      storeTableFooter: {
        "items-per-page-options": [10,20,50]
      },
      cropperLogoVisible: false,
      cropperGreetingVisible: false,
      showCategoryToolTip: false,
      invalidImageInput: {
        logo: false,
        greeting: false
      },
      urlRule: v => {

        if (!v) {
          return false
        } else if ( !(v.startsWith("http:") || v.startsWith("https:")) ) {
          return  'Eingabewert muss mit "http:" oder "https:" anfangen'
        } else {
          return true
        }
      }
    };
  },

  created () {

    this.selectedStoreEntry = JSON.parse(JSON.stringify(this.$store.getters["stores/emptyStore"]));
    if (this.$route.query.store) {

      if (this.$route.query.store !== "new") {

        this.updateSelectedStore(this.$route.query.store).then(() => {
          
          this.loading = false;
        }, () => {

          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
          this.loading = false;
        })        
      } else {

        this.loading = false;
      }
    }      
  },

  computed: {

    storeTableData () {

      return this.$store.getters["stores/storeList"];
    },

    maxStoreTableData () {

      return this.$store.getters["stores/maxStoreTableData"];
    },

    editDialogVisible () {

      return (this.$route.query.store ? true : false);
    },

    editDialogHeadline () {

      if (this.$route.query.store === "new") {
        
        return "Neuer Markt"
      } else {

        return this.selectedStoreEntry.name
      };
    },

    deleteDialogText () {

      return 'Wollen Sie den Markt "' + this.selectedStoreEntry.name + '" wirklich löschen?'
    }
  },

  watch: {

    $route (to,from ) {

      this.invalidImageInput.logo = false;
      this.invalidImageInput.greeting = false;
    },

    selectedStoreEntry: {

      deep: true,
      handler () {

          if (this.$refs.formStore) {

            this.$emit("storeFormChanges", true);
          }
      }
    }
  },

  methods: {

    onFilterChanged () {
      
      this.storeTableOptions.page = 1;
      this.updateTableOptions();
    },

    updateTableOptions () {

      this.loading = true;
      this.updateTable(this.storeTableOptions.page - 1, this.storeTableOptions.itemsPerPage, this.storeTableOptions.sortDesc[0], this.filterText)
      .then(() => {

        this.loading = false;
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        this.loading = false;
      })
    },

    updateTable (offset, count, sort_desc, filter) {

      return new Promise ((resolve, reject) => {

        this.$store.dispatch("stores/getStoreList", {
          path: "list",
          offset,
          count,
          filter,
          sort_desc
        }).then(() => {
          this.$store.dispatch("stores/getCompleteStoreCount", {
            path: "count",
            filter
            }).then(() => resolve(), () => reject());
        }, () => reject());
      })      
    },

    updateSelectedStore (storeId) {
      
      return new Promise ((resolve, reject) => {

        this.$store.dispatch("stores/getStore", {
          path: "info",
          id: storeId
        }).then(() => {

          this.selectedStoreEntry = JSON.parse(JSON.stringify(this.$store.getters["stores/currentStore"]));
          Promise.all([
            imageToBase64(this.selectedStoreEntry.logo_image_url),
            imageToBase64(this.selectedStoreEntry.greeting_image_url)
          ])
          .then ((responses) => {

            this.$set(this.selectedStoreEntry, 'logo_image', "data:image/png;base64," + responses[0]);
            this.$set(this.selectedStoreEntry, 'greeting_image', "data:image/png;base64," + responses[1]);
            resolve();
          });
        }, () => {

          reject()
        });
      });      
    },

    showDeleteDialog (store) {

      this.updateSelectedStore(store.id).then(() => {

        this.deleteDialogVisible = true;
      }, () => {

        this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
      })      
    },

    closeDeleteDialog () {

      this.deleteDialogVisible = false;
    },

    showEditDialog (store) {

      if (store) {

        this.updateSelectedStore(store.id).then(() => {

          this.$router.push({ query: { store: this.selectedStoreEntry.id } });
        }, () => {

          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        })        
      } else {

        this.selectedStoreEntry = JSON.parse(JSON.stringify(this.$store.getters["stores/emptyStore"]));
        this.$router.push({ query: { store: 'new' } });
      }      
    },

    closeEditDialog () {

      this.$router.push({ query: {} })
    },

    saveStore () {

      if(!this.$refs.formStore.validate() || !this.selectedStoreEntry.logo_image || !this.selectedStoreEntry.greeting_image) {

        this.showSnackbar("red", "Einige Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie die rot markierten Felder.");
        if (!this.selectedStoreEntry.logo_image) {

          this.invalidImageInput.logo = true;
        }
        if (!this.selectedStoreEntry.greeting_image) {

          this.invalidImageInput.greeting = true;
        }
        return;
      }

      this.$route.query.store === "new" ? this.createStore() : this.updateStore()
    },

    updateStore () {

      this.loading = true;
      this.$store.dispatch("stores/updateStore", {
        path: "update",
        store: this.selectedStoreEntry
      }).then(() => {

        this.showSnackbar("green", "Markt wurde erfolgreich gespeichert.");
        this.updateSelectedStore(this.selectedStoreEntry.id).then(() => {

          this.$emit("storeFormChanges", false);
          this.loading = false;
        }, () => {

          this.loading = false;
          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        });
      }, () => {

        this.showSnackbar("red", "Beim Speichern des Marktes ist ein Fehler aufgetreten.");
        this.loading = false;
      });
    },

    createStore () {

      this.loading = true;
      this.$store.dispatch("stores/createStore", {
        path: "create",
        store: this.selectedStoreEntry
      }).then((storeId) => {

        this.showSnackbar("green", "Markt wurde erfolgreich gespeichert.");
        this.updateSelectedStore(storeId).then(() => {

          this.$emit("storeFormChanges", false);
          this.loading = false;
          this.$router.push({ query: { store: storeId } });
        }, () => {

          this.loading = false;
          this.showSnackbar("red", "Beim Aktualisieren der Seite ist ein Fehler aufgetreten.");
        });
      }, () => {

        this.showSnackbar("red", "Beim Speichern des Marktes ist ein Fehler aufgetreten.");
        this.loading = false;
      });
    },

    deleteStore () {

      this.deleteDialogVisible = false;
      this.$store.dispatch("stores/deleteStore", {
        path: "delete",
        id: this.selectedStoreEntry.id
      }).then(() => {

        this.showSnackbar("green", "Markt wurde erfolgreich gelöscht.");
        this.updateTableOptions();
      }, () => {

        this.showSnackbar("red", "Beim Löschen des Marktes ist ein Fehler aufgetreten.");
      });      
    },

    saveCropperLogoFile (croppedFileUrl) {

      this.$set(this.selectedStoreEntry, 'logo_image', croppedFileUrl);
      if (this.selectedStoreEntry.logo_image) {

        this.invalidImageInput.logo = false;
      }
    },

    saveCropperGreetingFile (croppedFileUrl) {

      this.$set(this.selectedStoreEntry, 'greeting_image', croppedFileUrl);
      if (this.selectedStoreEntry.greeting_image) {

        this.invalidImageInput.greeting = false;
      }
    },
    
    imageLinkClicked (file) {

      let newTab = window.open();
      newTab.document.body.innerHTML = `<img src=${file}>`;
    }
  },
};
</script>

<style scoped>

  .invalid {

    color: red !important
  }

</style>
