export default {

    data: () => {

        return {

            snackbar: {

                visible: false,
                timeout: 1500,
                color: "",
                text: ""
            }
        }
    },

    methods: {

        showSnackbar (color, text, duration) {

            this.snackbar.color = color;
            this.snackbar.text = text;
            this.snackbar.duration = duration ? duration : 2000;
            this.snackbar.visible = true;
        }
    }
}