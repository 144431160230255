import axios from 'axios'
import { baseConfig } from '../../config.js';

const state = {

    storeList: [],
    storeListSelection: [],
    completeStoreCount: 0,
    emptyStore: {
        name: "",
        active: false,
        logo_image: null,
        greeting_headline: "",
        greeting_text: "",
        greeting_image: null,
        fee_info_text: "",
        categories: "",
        seller_invite_code: "",
        street_name: "",
        house_number: "",
        zip_code: "",
        city: "",
        email: "",
        phone: "",
        fax: "",
        web_shop_link_url: "",
        web_shop_link_text: ""
    },
    currentStore: {}
};

const getters = {

    storeList (state) {

        return state.storeList;
    },

    storeListSelection (state) {

        return state.storeListSelection;
    },

    storeNameSelectionById: (state) => (id) => {

        return state.storeListSelection.find((el) => el.id === id).name;
    },

    maxStoreTableData (state) {

        return state.completeStoreCount;        
    },

    emptyStore (state) {

        return state.emptyStore;
    },

    currentStore (state) {

        return state.currentStore;
    }
};

const mutations = {

    setStoreList (state, list) {
      
        state.storeList = list;
    },

    setStoreListSelection (state, list) {
      
        state.storeListSelection = list;
    },

    setCompleteStoreCount (state, count) {

        state.completeStoreCount = count;
    },

    setCurrentStore (state, store) {
        
        store.categories = store.categories.toString();
        state.currentStore = store;
    },
};

const actions = {
    
    getStoreList (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.store + payload.path, {
                params: {
                    "offset": payload.offset,
                    "count": payload.count,
                    "filter": payload.filter,
                    "sort_desc": payload.sort_desc
                }
            }).then((res) => {
                context.commit("setStoreList", res.data.stores);
                resolve();
            }, () => {                
    
                reject();
            });
        });
    },

    getStoreListSelection (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.store + payload.path, {
                params: { "filter": payload.filter }
            }).then((res) => {
                context.commit("setStoreListSelection", res.data.stores);
                resolve();
            }, () => {                
    
                reject();
            });
        });
    },

    getCompleteStoreCount (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.store + payload.path, {
                params: {
                    "filter": payload.filter
                }
            }).then((res) => {

                context.commit("setCompleteStoreCount", res.data)
                resolve();
            }, () => {

                reject();
            })
        })
    },

    getStore (context, payload) {

        return new Promise ((resolve, reject) => {

            axios.get(baseConfig.baseUrl + baseConfig.specificUrlPath.store + payload.path, {
                params: {
                    "id": payload.id
                }
            })
            .then((res) => {
                
                context.commit("setCurrentStore", res.data);
                resolve();
            }, () => {
    
                reject();
            });
        });        
    },
    
    createStore (context, payload) {

        let storeObject = JSON.parse(JSON.stringify(payload.store));
        storeObject.categories = storeObject.categories.split(",");

        return new Promise ((resolve, reject) => {

            axios.post(baseConfig.baseUrl + baseConfig.specificUrlPath.store + payload.path, storeObject)
            .then((res) => {

                resolve(res.data);
            }, () => {
    
                reject();
            });
        });        
    },

    updateStore (context, payload) {

        let storeObject = JSON.parse(JSON.stringify(payload.store));
        storeObject.categories = storeObject.categories.split(",");

        return new Promise ((resolve, reject) => {

            axios.post(baseConfig.baseUrl + baseConfig.specificUrlPath.store + payload.path, storeObject, {
                params: {
                    "id": storeObject.id
                },
            })
            .then(() => {

                resolve();
            }, () => {

                reject();
            });
        });
    },

    deleteStore (context, payload) {
        
        return new Promise ((resolve, reject) => {

            axios.delete(baseConfig.baseUrl + baseConfig.specificUrlPath.store + payload.path, {
                params: {
                    "id": payload.id
                }
            }).then(() => {
    
                resolve();
            }, () => {
    
                reject();
            });
        });
    }
};

export default {

    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
