import Vue from 'vue'
import Vuex from 'vuex'

import login from './modules/login'
import stores from './modules/stores'
import sellers from './modules/sellers'
import advertisements from './modules/advertisements'
import billings from './modules/billings'
import searchRankings from './modules/searchRankings'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    namespaced: true,
    login,
    stores,
    sellers,
    billings,
    searchRankings,
    advertisements
  }
})
